import React, {Component} from 'react';
import {connect} from 'react-redux';
import {blogActions} from '../../actions';
import {blogConstants} from '../../constants';
import Countdown from 'react-countdown-now';
import * as qs from 'query-string';
import Modal from 'react-responsive-modal';
class SmartMessage extends Component {
    constructor(props) {
        super(props);

        this.handleComplete = this.handleComplete.bind(this);
    }

    renderer({days, hours, minutes, seconds, completed}) {
        // if (completed) {
        //     return <span>Time up</span>;
        // } else {
        //     return '';
        // }
        return '';
    }

    handleComplete() {
        const {dispatch, blog} = this.props;
        dispatch(blogActions.trackingSmartMessage(blog.content.phone, blog.content.id, blog.content.data, blog.content.token));
    }

    componentDidMount() {
        const {dispatch} = this.props;
        if (this.props.history.action == 'POP' || this.props.history.action == 'PUSH') {
            var searchData = qs.parse(this.props.location.search);
            if (typeof searchData.data !== 'undefined' && typeof searchData.token !== 'undefined') {
                dispatch(blogActions.getSmartMessage(searchData.data, searchData.token));
            }
        }
    }

    renderCountDown(data){

        if(data.incentive_status === 'VALID'){
            return (<Countdown date={Date.now() + data.countdown_time*1000 }
                       renderer={this.renderer}
                       onComplete={this.handleComplete}
            />);
        }
    }
    onCloseModal() {
        const { dispatch } = this.props;
        dispatch(blogActions.cancel());

    }
     formatNumber(num) {
        if (num === undefined) {
            return '';
        }
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }
    render() {
        const {blog,trackingPosts} = this.props;
        var template = [];

        if (blog.type == blogConstants.BLOG_DETAIL) {
            var data = blog.content;
            template.push(
                <div className="row" key={data.id}>

                    <div className="col-12">
                        <div className="content">
                            <div>
                                <p dangerouslySetInnerHTML={{__html: data.content}}/>
                                {this.renderCountDown(data)}
                            </div>
                        </div>

                    </div>
                </div>
            );
        }

        return (

            <div id="app">
                {trackingPosts.openModal &&
                <Modal open={trackingPosts.openModal} onClose={this.onCloseModal.bind(this)} center
                       classNames={{
                           transitionEnter: 'transition-enter',
                           transitionEnterActive: 'transition-enter-active',
                           transitionExit: 'transition-exit-active',
                           transitionExitActive: 'transition-exit-active',
                           modal: 'tracking-modal-container',
                           closeIcon:'popup-close-button',
                           overlay:'popup-overlay'
                       }}
                       animationDuration={500}
                       styles={{}}
                >
                    <div className="popup-incentive">
                        <div className="popup-icon"></div>
                        <div className="popup-title">Bạn đã nhận được {this.formatNumber(data.incentive_amount)}đ</div>
                        <div className="popup-content">Chúc mừng bạn đã nhận được {this.formatNumber(data.incentive_amount)}đ. Vui lòng chờ trong vòng 24 giờ, tiền sẽ được cộng vào TK Ưu đãi của bạn.</div>

                    </div>
                </Modal>
                }

                <section id="promotion-blog">
                    <div className="container">
                        <div className="promotion detail">
                            {template}
                        </div>
                    </div>
                </section>
            </div>
        )
    };

}



function mapStateToProps(state) {
    const {blog,trackingPosts} = state;
    return {
        blog,trackingPosts
    };
}

const connectedRegisterPage = connect(mapStateToProps)(SmartMessage);
export {connectedRegisterPage as SmartMessage};