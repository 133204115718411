import config from 'config';
import axios from 'axios';
export const blogService = {
    getPosts,
    getSinglePost,
    getSmartMessage,
    trackingSmartMessage
};

function getPosts(postType) {
    let responseJson = { "postType": postType };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: responseJson
    };
    return axios(`${config.apiUrl}/api/v1/posts/listposts`, requestOptions);
}

function getSinglePost(postType, postId) {
    let responseJson = {

        "postType": postType,
        "postId": postId
    };

    const requestOptions = {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json' ,
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
        credentials: 'same-origin',
        data: responseJson
    };
    return axios(`${config.apiUrl}/api/v1/posts/getSinglePost`, requestOptions);
}

function getSmartMessage(data,token) {
    let responseJson = {
        "data": data,
        "token": token
    };

    const requestOptions = {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json' ,
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
        credentials: 'same-origin',
        data: responseJson
    };
    console.log(requestOptions);
    return axios(`${config.apiUrl}/api/v1/posts/getSmartMessage`, requestOptions);
}

function trackingSmartMessage(phone,postId,data,token) {
    let responseJson = {
        "phone": phone,
        "data": data,
        "token": token,
        "postId": postId
    };

    const requestOptions = {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json' ,
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
        credentials: 'same-origin',
        data: responseJson
    };
    return axios(`${config.apiUrl}/api/v1/posts/trackingSmartMessage`, requestOptions);
}
