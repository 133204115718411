import React, { Component } from 'react';

import { blogActions } from '../actions';
import { connect } from 'react-redux';
import { blogConstants } from '../constants';
class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    const { dispatch } = this.props;
    dispatch(blogActions.getList());


  }


  showDetail(id) {
    const { dispatch } = this.props;
    dispatch(blogActions.getDetail(id));
  }


  componentDidUpdate() {

  }


  render() {
    var template = [];
    const { blog } = this.props;
    if (blog.type == blogConstants.BLOG_LIST) {
      var PromotionContent = blog.content;
      if (PromotionContent instanceof Array) {
        for (var i = 0; i < PromotionContent.length; i++) {
          var element = PromotionContent[i];
          template.push(
            <div className="row blog-content" key={element.id}>
              <div className="col-md-6">
                <div className="image-wrapper" onClick={this.showDetail.bind(this, element.id)}>
                  <img className="responsive-img" src={element.thumnail_img} /> 
                </div>
              </div>
              <div className="col-md-6">
                <div className="content">
                  <span className="blog-title">
                    <h5 onClick={this.showDetail.bind(this, element.id)}>{element.title}</h5>
                  </span>
                    <p dangerouslySetInnerHTML={{ __html: element.excerpt }} />
                </div>
                <div className="read-more" onClick={this.showDetail.bind(this, element.id)}>
                  <span>XEM THÊM</span>

                </div>
              </div>
            </div>
          );
        }
      }
    }
    return (
      <section id="promotion-blog" >
        <div className="container">
          {template}
        </div>
      </section>
    )
  };
}

function mapStateToProps(state) {
  const { blog } = state;
  return {
    blog
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Promotion);
export { connectedRegisterPage as Promotion };
