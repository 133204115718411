export const blogConstants = {
    BLOG_LIST: 'BLOG_LIST',
    BLOG_DETAIL: 'BLOG_DETAIL',
    BLOG_FAILURE:'BLOG_REQUEST_FAILURE',
    BLOG_REQUEST:'BLOG_REQUEST',
    BLOG_TRACKING_SUCCESS:'BLOG_TRACKING_SUCCESS',
    BLOG_TRACKING_FAILURE: 'BLOG_TRACKING_FAILURE',
    BLOG_TRACKING_REQUEST: 'BLOG_TRACKING_REQUEST',
    BLOG_TRACKING_CANCEL: 'BLOG_TRACKING_CANCEL',
};
