import React, { Component } from 'react';

import Banner from './components/Banner';

import './assets/style/homepage.scss';
import { connect } from 'react-redux';

class HomePage extends Component {
    constructor(props) {
        super(props);

    }


    render() {

        return (
            <div id="app">
                <Banner />
            </div>
        );
    }
};

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(HomePage);
export { connectedApp as HomePage };

