import React, { Component } from 'react';
import Page from './static/terms_and_conditions.html';
import $ from "jquery";
var htmlDoc = {__html: Page};

export default class Terms extends Component {
    constructor(props){
        super(props);

    }
    componentDidMount() {
        $('.navigation').remove();
        $('#footer').remove();
        $('.terms').css('padding-top','50px');
        $('.back-to-top').remove()
    };
    render(){
        return (<div dangerouslySetInnerHTML={htmlDoc} />)
    }}