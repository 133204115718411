import React, { Component } from 'react';
import Page from './static/privacy.html';
var htmlDoc = {__html: Page};

export default class Privacy extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return (<div dangerouslySetInnerHTML={htmlDoc} />)
    }}