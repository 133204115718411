// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/banner/2019/text_banner.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".banner-slide-in-left{-webkit-animation:slide-in-left 6s infinite;animation:slide-in-left 6s infinite}@-webkit-keyframes banner-slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}@keyframes banner-slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}.banner-text{position:absolute;top:40%;left:5%}.bg-text{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;height:240px;width:603px}@media screen and (min-width: 1425px){.banner-text{left:5%}}@media screen and (min-width: 1600px){.banner-text{left:10%}}\n", ""]);
// Exports
module.exports = exports;
