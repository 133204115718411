
import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { blog } from './blog.reducer';
import { trackingPosts } from './tracking.reducer';

const rootReducer = combineReducers({
  alert,
  blog,
    trackingPosts
});

export default rootReducer;