import React from 'react';
import { render } from 'react-dom';
import {App} from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/style/core.scss';
import './assets/style/mobile.scss';

import '../node_modules/font-awesome/css/font-awesome.min.css';
import { store } from './helpers';
import { Provider } from 'react-redux';

 
render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);