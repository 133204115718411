
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { blogActions } from '../../actions';
import { blogConstants } from '../../constants';
import * as qs from 'query-string';
class PromotionDetail extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        const { dispatch } = this.props;
        if (this.props.history.action == 'POP'||this.props.history.action == 'PUSH') {
            var searchData = qs.parse(this.props.location.search)
            dispatch(blogActions.getDetail(searchData.id,searchData.type));

        }
    }
    render() {
        const { blog } = this.props;
        var template = [];
        if (blog.type == blogConstants.BLOG_DETAIL) {
            var PromotionContent = blog.content;
            template.push(
                <div className="row" key={PromotionContent.id}>

                    <div className="col-12">
                        <div className="content">
                            <div>
                            <p dangerouslySetInnerHTML={{ __html: PromotionContent.content }} />
                            </div>
                        </div>

                    </div>
                </div>
            );
        }

        return (
            <div id="app">
                <section id="promotion-blog">
                    <div className="container">
                        <div className="promotion detail">
                            {template}
                        </div>
                    </div>
                </section>
            </div>
        )
    };

}


// export default PromotionDetail;
function mapStateToProps(state) {
    const { blog } = state;
    return {
        blog
    };
}

const connectedRegisterPage = connect(mapStateToProps)(PromotionDetail);
export { connectedRegisterPage as PromotionDetail };