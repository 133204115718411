import { blogConstants } from '../constants';
import { blogService } from '../services';
import { history } from '../helpers';
import { alertActions } from './';

export const blogActions = {
    getDetail,
    getList,
    getSmartMessage,
    trackingSmartMessage,
    cancel
};

function getDetail(postId, postType = 'PROMOTION') {
    //let postType = 'PROMOTION';
    postType = (typeof postType !== 'undefined') && postType !== null && postType !== '' ?  postType : 'PROMOTION';
    return dispatch => {
        dispatch(request());
        blogService.getSinglePost(postType, postId)
            .then(function (response) {

                if (response.status == '200') {
                    var result = response.data.msg[0];
                    dispatch(success(result));
                    history.push('/detail/' + result.slug +'?type='+ postType + '&id=' + result.id);
                }
                else
                    dispatch(failure(error.toString()));
            }).catch(function (error) {
                console.log(error);
            });

    }
    function request() {
        return { type: blogConstants.BLOG_REQUEST }
    }
    function success(promotion) {
        return { type: blogConstants.BLOG_DETAIL, content: promotion }
    }
    function failure(error) {
        return { type: blogConstants.BLOG_FAILURE, error }
    }
}


function getSmartMessage(data,token) {
    return dispatch => {
        dispatch(request());
        blogService.getSmartMessage(data,token)
            .then(function (response) {
                if (response.status == '200') {
                    var result = response.data.msg[0];
                    dispatch(success(result));
                    history.push('/app/' + '?data=' + result.data+'&token=' + result.token);
                }
                else
                    dispatch(failure(error.toString()));
            }).catch(function (error) {
                console.log(error);
            });

    }
    function request() {
        return { type: blogConstants.BLOG_REQUEST }
    }
    function success(promotion) {
        return { type: blogConstants.BLOG_DETAIL, content: promotion }
    }
    function failure(error) {
        return { type: blogConstants.BLOG_FAILURE, error }
    }
}

function trackingSmartMessage(phone,postId,data,token) {
    return dispatch => {
        dispatch(request());
        blogService.trackingSmartMessage(phone,postId,data,token)
            .then(function (response) {

                if (response.status == '200') {
                    var result = response.data.msg[0].result;
                    if(result>0){
                        dispatch(success(phone));
                    }
                    else{
                        dispatch(failure(phone));
                    }
                }
                else
                    dispatch(failure(error.toString()));
            }).catch(function (error) {
                console.log(error);
            });
    }
    function request() {
        return { type: blogConstants.BLOG_TRACKING_REQUEST }
    }
    function success(promotion) {
        return { type: blogConstants.BLOG_TRACKING_SUCCESS, content: promotion }
    }
    function failure(error) {
        return { type: blogConstants.BLOG_TRACKING_FAILURE, error }
    }
}

function getList() {
    let postType = 'PROMOTION';
    return dispatch => {
        dispatch(request());
        blogService.getPosts(postType)
            .then(function (response) {
                if (response.status == '200') {
                    var result = response.data.msg;
                    dispatch(success(result));
                }
                else
                    dispatch(failure(error.toString()));
            }).catch(function (error) {
                console.log(error);
            });
    }
    function request() {
        return { type: blogConstants.BLOG_REQUEST }
    }
    function success(promotions) {
        return { type: blogConstants.BLOG_LIST, content: promotions }
    }
    function failure(error) {
        return { type: blogConstants.BLOG_FAILURE, error }
    }
}

function cancel() {
    return dispatch => {
        dispatch(cancel());
    }
    function cancel() {
        return { type: blogConstants.BLOG_TRACKING_CANCEL }
    }
}