import { blogConstants } from '../constants';

export function blog(state = {}, action) {

    switch (action.type) {
        case blogConstants.BLOG_LIST:
            return {
                type: blogConstants.BLOG_LIST,
                content: action.content
            };
        case blogConstants.BLOG_DETAIL:
            return {
                type: blogConstants.BLOG_DETAIL,
                content: action.content
            };
        case blogConstants.BLOG_REQUEST:
            return { type: blogConstants.BLOG_REQUEST, }
        case blogConstants.BLOG_FAILURE:
            return { type: blogConstants.BLOG_FAILURE, }
        default:
            return state
    }
}
