import {blogConstants} from '../constants';

export function trackingPosts(state = {}, action) {


    switch (action.type) {
        case blogConstants.BLOG_TRACKING_REQUEST:
            return {
                registering: true,
                openModal: false,
                submitted: true
            };
        case blogConstants.BLOG_TRACKING_SUCCESS:
            return {
                openModal: true,
                submitted: true
            };
        case blogConstants.BLOG_TRACKING_FAILURE:
            return {
                openModal: false,
                submitted: false
            };
        case blogConstants.BLOG_TRACKING_CANCEL:
            return {
                openModal: false
            };
        default:
            return state
    }
}
