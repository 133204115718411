import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import PromotionPage from './PromotionPage';
import { PromotionDetail } from './components/Blogs/PromotionDetail';
import { HomePage } from './HomePage';
import Privacy from './Privacy';
import Privacy4App from './Privacy4App';
import Terms from './Terms';
import Terms4App from './Terms4App';
import { connect } from 'react-redux';
import { history } from './helpers';
import {SmartMessage} from "./components/Blogs/SmartMessage";
class App extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="banner-top">
                <Router history={history}>
                    <div >
                        <Route exact path='/' component={HomePage} />
                        <Route path='/promotion' component={PromotionPage}></Route>
                        <Route path='/policies/privacy' component={Privacy}></Route>
                        <Route path='/policies/terms_and_conditions' component={Terms}></Route>
                        <Route path='/app/policies/privacy' component={Privacy4App}></Route>
                        <Route path='/app/policies/terms_and_conditions' component={Terms4App}></Route>
                        <Route path='/detail/:slug?' component={PromotionDetail}></Route>
                        <Route path='/app/:slug?' component={SmartMessage}></Route>
                    </div>
                </Router>

            </div>
        )
    }
}
function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };

// export default App;