import React, { Component } from 'react';
import Page from './static/privacy.html';
var htmlDoc = {__html: Page};
import $ from 'jquery';
export default class Privacy extends Component {
    constructor(props){
        super(props);
    }
    componentDidMount() {
        $('.navigation').remove();
        $('#footer').remove();
        $('.back-to-top').remove()
        $('.privacy').css('padding-top','50px');

    };
    render(){
        return (<div dangerouslySetInnerHTML={htmlDoc} />)
    }}