import { alertConstants } from '../constants';

export const alertActions = {
    success,
    error,
    clear,
    invalid
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR};
}
function invalid() {
    return { type: alertConstants.INVALID};
}