import React, { Component } from 'react'
import '../../assets/style/simle-slider.scss';
class SimpleSlider extends Component {
    constructor(props) {
        super(props)
    }
    render() {

        return (

            <div id="banner_simple_slide" className="banner_simple_slide">
                <div className="banner-text">
                    <div className="banner-slide-in-left">
                        <div className="bg-text"></div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SimpleSlider