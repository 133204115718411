import React, { Component } from 'react';
import Page from './static/terms_and_conditions.html';
var htmlDoc = {__html: Page};

export default class Terms extends Component {
    constructor(props){
        super(props);

    }

    render(){
        return (<div dangerouslySetInnerHTML={htmlDoc} />)
    }}