import React from 'react';
import SimpleSlider from './Banners/SimpleSlider';
const Banner = () => {

    return (
        <div className="slider-wrapper">
            <SimpleSlider/>
        </div>
    );
}

export default Banner;